import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme'
import aphroditeInterface from 'react-with-styles-interface-aphrodite'

export const registerCalendarTheme = () => {
  ThemedStyleSheet.registerInterface(aphroditeInterface)
  ThemedStyleSheet.registerTheme({
    reactDates: {
      ...DefaultTheme.reactDates,
      color: {
        ...DefaultTheme.reactDates.color,
        highlighted: {
          backgroundColor: '#ffe8bc',
          backgroundColor_active: '#ffce71',
          backgroundColor_hover: '#ffce71',
          color: '#484848',
          color_active: '#484848',
          color_hover: '#484848'
        },
        hoveredSpan: {
          backgroundColor: '#b2f1ec',
          backgroundColor_active: '#80e8e0',
          backgroundColor_hover: '#b2f1ec',
          borderColor: '#80e8e0',
          borderColor_active: '#80e8e0',
          borderColor_hover: '#80e8e0',
          color: '#007a87',
          color_active: '#007a87',
          color_hover: '#007a87'
        }
      }
    }
  })
}

export const Themes = {
  colors: {
    translucent: 'rgba(231, 231, 231, 0.8)',
    purple: 'rgb(50, 50, 78)',
    white: 'rgb(255, 255, 255)'
  },
  registerCalendarTheme
}
